import Vue from 'vue'
import { merge } from 'lodash'
import Base from '../crud-base-module.js'

export default merge({
    state: {
      projectsCurrentPage: 1,
      projectsPageSize: 10
    },
    getters: {
      projectsCurrentPage: state => state.projectsCurrentPage,
      projectsPageSize: state => state.projectsPageSize,
      openProjects: (state, rootState) => {
        return state.projects.filter(p => p.state !== 'ARCHIVED')
      },
      allProjects: (state, rootState) => {
        if (rootState.currentUserRights.includes('Alle Projekte_')) {
          return state.projects.filter(project => {
            if (project.is_private) {
              
                if (!project.projectPersons) {
                  return false
                }

                if (!rootState.currentUserRights.includes('project.can-see.private')) {
                  return false
                }

                return project.projectPersons.map(pp => pp.user_id).includes(rootState.currentUser.id)
            }
            return true
        })
        } else {
          return state.projects.filter(p => {
            if (!p.projectPersons) {
              return false
            }
            return p.projectPersons.map(pp => pp.user_id).includes(rootState.currentUser.id)
          }).filter(project => {
            if (project.is_private) {
                return rootState.currentUserRights.includes('project.can-see.private')
            }
            return true
        })
        }
      },
      userProjects: (state, rootState) => {
        const projectIdsFromFavorites = (rootState.currentUser.fav_projects ? rootState.currentUser.fav_projects.split(',') : []).map(id => parseInt(id))
        const projectIdsFromResponsibility = state.projects.filter(p => {
          if (!p.projectPersons) {
            return false
          }
          return p.projectPersons.map(pp => pp.user_id).includes(rootState.currentUser.id)
        }).map(p => p.id)

        const mergedIds = projectIdsFromFavorites.concat(projectIdsFromResponsibility)

        const uniqueIds = [...new Set(mergedIds)]
        return state.projects.filter(p => uniqueIds.includes(p.id))
      },
      // allProjects: state => state.projects
    },
    mutations: {
      'SET_PROJECTS_CURRENT_PAGE' (state, val) {
        state.projectsCurrentPage = val
      },
      'SET_PROJECTS_PAGE_SIZE' (state, val) {
        state.projectsPageSize = val
        state.projectsCurrentPage = 1
      },
    },
    actions: {
        getFullProject({ commit }, id) {
          return Vue.http.post(`project/query`, {
            'and': {
              'id': id
            },
            'with': {
              'customer': {
                'one': 'customer',
                'this': 'customer_no',
                'that': 'No',
              },
              'komm_checked_user': {
                'one': 'users',
                'this': 'komm_checked_user',
                'that': 'id',
                'query': {
                  'select': ['id', 'name']
                }
              },
              'bhb_checked_user': {
                'one': 'users',
                'this': 'bhb_checked_user',
                'that': 'id',
                'query': {
                  'select': ['id', 'name']
                }
              },
              'documents': {
                'many': 'document',
                'this': 'id',
                'that': 'item_id',
                'query': {
                    'and': {
                        'type': 'project',
                    }
                }
              },
              'projectPersons': {
                'many': 'project_person',
                'this': 'id',
                'that': 'project_id',
                'query': {
                  'with': {
                    'user': {
                        'one': 'users',
                        'this': 'user_id',
                        'that': 'id'
                    },
                    'addresses': {
                        'many': 'person_address',
                        'this': 'user_id',
                        'that': 'user_id'
                    }
                  }
                }
              }
            }
          })
            .then(res => res.json())
            .then(projects => {
              commit('project', projects[0]);
              return projects[0]
            })
        },
        loadAllProjects ({ commit }) {
          return Vue.http.post(`project/query`, {
              'with': {
                'milestones': {
                    'many': 'milestone',
                    'this': 'id',
                    'that': 'project_id',
                },
                'rides': {
                  'many': 'ride',
                  'this': 'id',
                  'that': 'project_id'
                },
                'projectAddresses': {
                  'many': 'project_address',
                  'this': 'id',
                  'that': 'project_id'
                },
                'projectPersons': {
                  'many': 'project_person',
                  'this': 'id',
                  'that': 'project_id',
                  'query': {
                    'with': {
                      'user': {
                          'one': 'users',
                          'this': 'user_id',
                          'that': 'id',
                          'query': {
                            'select':['id', 'name']
                          }
                            },
                      'addresses': {
                          'many': 'person_address',
                          'this': 'user_id',
                          'that': 'user_id'
                      }
                    }
                  }
                }
              }
            })
            .then(res => res.json())
            .then(projects => {
                commit('projects', projects);
                return projects
            })
        },
        loadUserProjects ({ commit }) {
          return Vue.http.post(`project/query`, {
              'with': {
                'milestones': {
                    'many': 'milestone',
                    'this': 'id',
                    'that': 'project_id'
                },
                'projectAddresses': {
                  'many': 'project_address',
                  'this': 'id',
                  'that': 'project_id'
                },
                'projectPersons': {
                  'many': 'project_person',
                  'this': 'id',
                  'that': 'project_id',
                  'query': {
                    'with': {
                      'user': {
                          'one': 'users',
                          'this': 'user_id',
                          'that': 'id'
                      },
                      'addresses': {
                          'many': 'person_address',
                          'this': 'user_id',
                          'that': 'user_id'
                      }
                    }
                  }
                }
              }
            })
            .then(res => res.json())
            .then(projects => {
                commit('userProjects', projects);
                return projects
            })
        },
    }
}, Base('project'))

/*

Das Projekt.
{
  'key': 'string',
  'name': 'string',
  'template': true,
  'functions': 'string',
  'pt_number': 'string',
  'path': 'string',
  'state': 'string',
  'description': 'string'
}
*/
