<template>
  <div class="card">
    <div class="border-0 card-header">
      <div class="row">
        <div class="col-6">
          <h3 class="mb-0">{{ $t('message.allProjects') }}</h3>
        </div>
        <div class="col-6 text-right">

          <base-split-button type="primary" size="sm" :text="$t('message.newProject')" @primary-click="create"
            v-if="currentUserRights.includes('Neues Projekt_Projekt erstellen')" :menu-on-right="true">
            <a class="dropdown-item" v-for="tmpl in templates" @click="copy(tmpl.id)" :key="tmpl.id">{{ tmpl.name }}</a>
            <a class="dropdown-item" @click="create()">{{ $t('message.emptyProject') }}</a>
          </base-split-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col m-3">
        <base-input v-model="searchText" :placeholder="$t('global.search')"></base-input>
      </div>
    </div>
    <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="paginatedItems"
      @sort-change="sortByDate">
      <el-table-column label="" prop="fav" width="70" v-if="currentUserRights.includes('project.favourite.cansee')">
        <template v-slot="{ row }">
          <a @click="toggleFavProject(row.id)">
            <i class="fa fa-star" v-bind:class="[isFavorite(row) ? 'active-star' : 'inactive-star']"></i>
          </a>
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.project')" prop="name" min-width="200" sortable="custom">
        <template v-slot="{ row }">
          <div class="d-flex">
            <router-link :to="`/project/${row.id}`">{{ row.name }}</router-link>
            <el-badge :value="unreadProjectNotifications(row.id).length" class="project-notification-item"
              v-if="unreadProjectNotifications(row.id).length > 0 && currentUserRights.includes('Dashboard Projekt A_Benachrichtigungsfeld')">
              <i class="fas fa-bell faa-shake animated"></i>
            </el-badge>
            <i v-if="row.state == 'ARCHIVED'" class="fas fa-archive ml-1"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.projectId')" prop="key" sortable="custom" width="140">

      </el-table-column>
      <el-table-column :label="$t('message.contactPerson')" prop="ansprech" width="250">
        <template v-slot="{ row }">
          <div class="avatar-group">
            <avatar v-for="user in getProjectPersons(row.projectPersons)" :user-id="user.user_id" :key="user.id">
            </avatar>
          </div>
        </template>
        <!-- <div class="avatar-group">
                    <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                       data-original-title="Ryan Tompson">
                        <img alt="Image placeholder" src="img/theme/bild1.png">
                    </a>
                    <a href="#" class="avatar avatar-sm rounded-circle" data-toggle="tooltip"
                       data-original-title="Romina Hadid">
                        <img alt="Image placeholder" src="img/theme/bild2.png">
                    </a>
                </div> -->
      </el-table-column>
      <!-- <el-table-column :label="$t('message.eventDate')"
                prop="earliest"
                width="220"
                sortable
                >
                <template v-slot="{row}">
                    <span>{{getProjectPeriod(row)}}</span>
                </template>
            </el-table-column> -->
      <el-table-column :label="$t('message.eventDate-from')" prop="from" width="220" sortable="custom">
        <template v-slot="{ row }">
          <span>{{ row.from | date }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.eventDate-to')" prop="to" width="220" sortable="custom">
        <template v-slot="{ row }">
          <span>{{ row.to | date }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('milestone.deadline-druck')" prop="dealineDate" sortable="custom" width="130">
      </el-table-column>
      <el-table-column :label="$t('message.ladeTermin')" prop="ladetermin" sortable="custom" width="130">
        <template v-slot="{ row }">
          <span>{{ getLoadingDate(row) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Status" prop="status" width="210" align="center" header-align="center" fixed="right"
        v-if="currentUserRights.includes('component.project-table.status')">
        <template v-slot="{ row }">
          <project-status-stacked-bar :progress="row.progress" :project="row"></project-status-stacked-bar>
        </template>
      </el-table-column>
      <el-table-column align="center" width="90" fixed="right"
        v-if="currentUserRights.includes('component.project-table.menu.isvisible')">
        <template v-slot="{ row }">
          <el-dropdown trigger="click" @command="handleCommand" class="dropdown">
            <span class="btn btn-sm btn-icon-only text-light">
              <i class="fas fa-ellipsis-v mt-2"></i>
            </span>
            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
              <el-dropdown-item :command="{ cmd: 'edit', item: row.id }"
                v-if="currentUserRights.includes('Dashboard Projekt A_Bearbeitung der Projektdaten')">
                  {{ $t('message.edit') }}
              </el-dropdown-item>
              <el-dropdown-item :command="{ cmd: 'openCopyModal', item: row.id }"
                v-if="currentUserRights.includes('Neues Projekt_Projekt erstellen')"
                v-loading.fullscreen.lock="isCopying">
                  {{ $t('message.copy') }}
              </el-dropdown-item>
              <!-- <a class="dropdown-item" @click="del(row)">Löschen</a> -->
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <div class="card-footer py-4 d-md-flex justify-content-between align-items-center hasArchiveCheckbox">
      <el-pagination background :current-page="currentPage" @current-change="onCurrentChange" :total="items.length"
        :page-count="pageCount" :page-size="pageSize" layout="prev, pager, next">
      </el-pagination>
      <base-checkbox v-model="showArchived" v-if="currentUserRights.includes('Archiv_Ansicht/ Übersicht')">
        {{ $t('message.showArchived') }}
      </base-checkbox>
    </div>

    <modal class="copy-modal" :show.sync="showCopyModal">
      <h6 slot="header" class="modal-title">
        {{ $t('message.copy') }}
        <span class="ml-3 small" v-if="checkedLocations.length > 0">{{ checkedLocations.length }}
          Einsatzort<span v-if="checkedLocations.length > 1">e</span> ausgewählt</span>
      </h6>

      <div class="d-flex justify-content-around" v-if="createProjectPart == false">
        <div class="text-center">
          <base-button type="primary" @click="copy(projectToCopy)">Komplettes Projekt kopieren
          </base-button>
          <br><span class="small">(alle Daten sofort kopieren)</span>
        </div>

        <div class="text-center">
          <base-button type="primary" @click="createProjectPart = true">Tochterprojekt erstellen
          </base-button>
          <br><span class="small">(nur ausgewählte Einsatzorte)</span>
        </div>
      </div>

      <div v-if="createProjectPart">
        <base-input v-model="searchLocations" :placeholder="$t('global.search')"></base-input>
        <el-checkbox-group v-model="checkedLocations">
          <div class="d-flex flex-wrap">
            <div class="mr-2" v-for="loc in filteredLocations" :key="loc.id">
              <el-checkbox border :label="loc.id">{{ loc.name }}</el-checkbox>
            </div>
          </div>
        </el-checkbox-group>
      </div>

      <template slot="footer">
        <div class="d-md-flex w-100 justify-content-between">
          <base-button v-if="createProjectPart" type="link" @click="createProjectPart = false">{{
            $t("message.back") }}
          </base-button>
          <base-button v-if="checkedLocations.length > 0" type="primary"
            @click="copy(projectToCopy, true)">Tochterprojekt anlegen
          </base-button>
          <base-button type="link" :class="checkedLocations.length > 0 ? '' : 'ml-auto'"
            @click="showCopyModal = false">{{ $t("message.close") }}
          </base-button>
        </div>
      </template>
    </modal>

  </div>
</template>

<script>
import Vue from "vue";
import ProjectStatusStackedBar from '@/components/ProjectStatusStackedBar.vue'
import ProjectStatusBarLegend from '@/components/ProjectStatusBarLegend.vue'
import { mapGetters, mapActions } from 'vuex'
import { formatDate } from '@/util/data'
import moment from 'moment'
import { debounce } from 'lodash'

export default {
  name: 'project-table',
  components: {
    ProjectStatusStackedBar,
    ProjectStatusBarLegend
  },
  props: {
    projects: {
      type: Array
    },
    pageSize: {
      type: Number,
      default: 10
    },
  },
  data() {
    return {
      showArchived: false,
      favs: [],
      search: '',
      sortBy: 'from',
      sortOrder: 1,
      locations: [],
      searchLocations: '',
      checkedLocations: [],
      isCopying: false,
      showCopyModal: false,
      projectToCopy: null,
      createProjectPart: false
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'internalPersonTopics',
      'currentUserRights',
      'unreadProjectNotifications'
    ]),
    templates() {
      return this.projects.filter(p => p.template);
    },
    searchText: {
      get() {
        return this.search
      },
      set: debounce(function (val) {
        this.search = val
      }, 500)
    },
    items() {
      const self = this
      let results = this.projects

      if (this.searchText) {
        results = results.filter(result => result.name.toLowerCase().includes(this.searchText.toLowerCase()) || result.key.includes(this.searchText))
      }

      return results
        .filter(project => {
          if (this.showArchived) {
            return project.state === 'NEW' || project.state === 'ARCHIVED'
          } else {
            return project.state === 'NEW'
          }
        })
        .map(project => {
          const fromDates = (project.projectAddresses || [])
            .filter(pa => pa.date_from !== null)
            .map(pa => pa.date_from);
          const toDates = (project.projectAddresses || [])
            .filter(pa => pa.date_to !== null)
            .map(pa => pa.date_to);
          const { length, 0: from, [length - 1]: to } = [...fromDates, ...toDates]
            .sort((a, b) => new Date(a) - new Date(b))
            .map(d => new Date(d));

          if (project.projectAddresses) {
            const deadlineDates = project.projectAddresses
              .filter(pa => pa.deadline_print !== null)
              .map(pa => pa.deadline_print)
              .sort((a, b) => new Date(a) - new Date(b))

            if (deadlineDates.length > 0) {
              project.dealineDate = formatDate(deadlineDates[0])
            }
          }

          project.from = from
          project.to = to
          return project
        })
        .sort((rowA, rowB) => {
          if (!this.sortBy) {
            return 0
          }

          if (['from', 'to', 'dealineDate', 'ladetermin'].includes(self.sortBy)) {
            const a = moment(rowA[self.sortBy], 'DD.MM.YYYY')
            const b = moment(rowB[self.sortBy], 'DD.MM.YYYY')
            if (!a.isValid()) {
              return 1
            }

            if (!b.isValid()) {
              return -1
            }
            const res = self.sortOrder * ((+a) - (+b))

            return res
          }
          return self.sortOrder * rowA[self.sortBy].localeCompare(rowB[self.sortBy])
        })
    },
    pageCount() {
      return Math.ceil(this.items.length / this.pageSize);
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.pageSize
      const end = start + this.pageSize
      return this.items.slice(start, end)
    },
    currentPage: {
      get() {
        return this.$store.getters.projectsCurrentPage
      },
      set(val) {
        this.$store.commit('SET_PROJECTS_CURRENT_PAGE', val)
      }
    },
    filteredLocations() {
      let result = this.locations;
      if (this.searchLocations) {
        result = this.locations.filter(item => {
          return item.name.toLowerCase().includes(this.searchLocations.toLowerCase())
        })
      }

      return result.sort((left, right) =>
        left.name.localeCompare(right.name));
    }
  },
  watch: {
    currentUser() {
      this.updateFavs()
    }
  },
  methods: {
    ...mapActions([
      'toggleFavProject',
      'getAddressesByProjectId'
    ]),
    loadLocations: async function () {
      let addresses = await this.getAddressesByProjectId(this.projectToCopy);
      this.locations = addresses.map(item => item.address);
    },
    openCopyModal(id) {
      this.projectToCopy = id;
      this.createProjectPart = false;
      this.showCopyModal = true;
      this.checkedLocations = [];
      this.loadLocations();
    },
    getProjectPersons(persons) {
      const topicVals = this.internalPersonTopics.map(t => t.val)
      return persons ? persons.filter(p => topicVals.includes(p.topic)) : []
    },
    getProjectPeriod(project) {
      if (!project.projectAddresses) {
        return
      }

      const fromDates = project.projectAddresses
        .filter(pa => pa.date_from !== null)
        .map(pa => pa.date_from);
      const toDates = project.projectAddresses
        .filter(pa => pa.date_to !== null)
        .map(pa => pa.date_to);
      const { length, 0: from, [length - 1]: to } = [...fromDates, ...toDates]
        .sort((a, b) => {
          return new Date(a) - new Date(b)
        })
        .map(d => formatDate(new Date(d)));

      if (!from || !to) {
        return ''
      }

      return `${from} - ${to}`
    },
    getLoadingDate(project) {
      if (!project.rides) {
        return
      }

      const loadingDates = project.rides
        .filter(pa => pa.load !== null)
        .map(pa => new Date(pa.load))
        .sort((a, b) => {
          return a - b
        })

      return formatDate(loadingDates[0])
    },
    getMilestoneByPhase(row, phase) {
      if (row.milestones) {
        const milestone = row.milestones.find(m => m.phase === phase);
        if (!milestone) {
          return null
        }
        const date = new Date(milestone.due);
        // todo: Muss abhängig von Benutzersprache sein.
        // DS: laut matthias ist einheitliche darstellung DD.MM.YYYY gewünscht.
        return formatDate(date);
      }
      return null

    },
    updateFavs() {
      const ids = this.currentUser.fav_projects ? this.currentUser.fav_projects.split(',') : [];
      this.favs = ids.map(id => parseInt(id))
    },
    isFavorite(project) {
      return this.favs.indexOf(project.id) != -1;
    },
    edit(id) {
      this.$router.push({ path: `/project/${id}/edit` })
    },
    create() {
      this.$router.push({ path: `/projects/add` })
    },
    copy(id, copyPart = false) {
      this.showCopyModal = false;

      const loading = this.$loading({
        lock: true,
        text: this.$t('message.copying-in-progress'),
      })

      if (copyPart) {
        let locationIds = JSON.stringify(this.checkedLocations);
        return Vue.http.get(`project/${id}/copy-part`, { params: { locations: locationIds } })
          .then(res => {
            loading.close()
            const id = res.body.id;
            this.$router.push({ path: `/project/${id}/edit`})
          })
          .catch(error => {
            loading.close()
            this.$notify({
              title: 'Error',
              message: error.body.message,
              type: 'error'
            })
          })
      } else {
        return Vue.http.get(`project/${id}/copy`)
          .then(res => {
            loading.close()
            const id = res.body.id;
            this.$router.push({ path: `/project/${id}/edit` })
          })
          .catch(error => {
            loading.close()
            this.$notify({
              title: 'Error',
              message: error.body.message,
              type: 'error'
            })
          })
      }
    },
    del(row) {
      this.$confirm(`Wollen Sie das Projekt "${row.name}" projekt wirklich löschen?`, 'Löschen', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Abbrechen',
        type: 'warning',
        center: false
      }).then(() => {
        this.$store.dispatch('deleteProject', { id: row.id })
          .then(() => this.$store.dispatch('findProjects'))
      })
    },
    onCurrentChange(pageNumber) {
      this.currentPage = pageNumber
    },
    sortByDate(e) {
      if (e.order === null) {
        this.sortBy = 'from'
        this.sortOrder = 1
        return
      }

      this.sortBy = e.prop
      this.sortOrder = e.order === 'ascending' ? 1 : -1
    },
    handleCommand(c) {
      return this[c.cmd](c.item);
    }
  },
  mounted() {
    this.updateFavs()
  }
}
</script>

<style>
.copy-modal .modal-body {
  padding-top: 0;
}

@media (max-width: 1023px) {
  .el-table__fixed-right {
    display: none !important;
  }

}

@media (max-width: 767px) {
  .hasArchiveCheckbox .custom-control.custom-checkbox {
    padding-top: 2rem;
  }
}
</style>