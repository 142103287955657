import DashboardLayout from '../views/Layout/DashboardLayout.vue';
import AuthLayout from '../views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '../views/GeneralViews/NotFoundPage.vue';

// Calendar
const Calendar = () => import(/* webpackChunkName: "extra" */ '../views/Calendar/Calendar.vue');
const NewsDetailView = () => import(/* webpackChunkName: "extra" */ '../views/News/NewsDetails.vue');

// Tasks
const Tasks = () => import(/* webpackChunkName: "extra" */ '../views/Tasks/Tasks.vue');

// Logistic
const Logistic = () => import(/* webpackChunkName: "extra" */ '../views/Logistic/Logistic.vue');

// Dashboard pages
// const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Dashboard.vue');
const MyDashboard = () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/MyDashboard.vue');
const ProjectDashboard = () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/ProjectDashboard.vue');
const ProjectDashboardLayout = () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/ProjectDashboardLayout.vue');

const DataInput = () => import(/* webpackChunkName: "datainpit" */ '../views/Dashboard/Project/DataInput/DataInput.vue');
const DataInputForm = () => import(/* webpackChunkName: "datainpit" */ '../views/Dashboard/Project/DataInput/DataInputForm.vue');
const Offer = () => import(/* webpackChunkName: "offer" */ '../views/Dashboard/Project/Offer/Offer.vue');
const BHB = () => import(/* webpackChunkName: "bhb" */ '../views/Dashboard/Project/BHB/BHB.vue')
const BHBDetailView = () => import(/* webpackChunkName: "bhb" */ '../views/Dashboard/Project/BHB/DetailView/index.vue')

const ProjectDocs = () => import(/* webpackChunkName: "projectdocs" */ '../views/Dashboard/Project/Docs/Docs.vue')
const AssemblyPlanning = () => import(/* webpackChunkName: "assembly" */ '../views/Dashboard/Project/AssemblyPlanning/AssemblyPlanning.vue');
const LoadingList = () => import(/* webpackChunkName: "loadinglist" */ '../views/Dashboard/Project/LoadingList/LoadingList.vue');
const Purchase = () => import(/* webpackChunkName: "purchase" */ '../views/Dashboard/Project/Purchase/Purchase.vue');
const PickingList = () => import(/* webpackChunkName: "pickinglist" */ '../views/Dashboard/Project/PickingList/PickingList.vue');
const ReStorageList = () => import(/* webpackChunkName: "restorage" */ '../views/Dashboard/Project/ReStorageList/ReStorageList.vue');
const InfoPool = () => import(/* webpackChunkName: "infopool" */ '../views/InfoPool/InfoPool.vue');
const Debriefing = () => import(/* webpackChunkName: "admin" */ '../views/Dashboard/Project/Debriefing/Index.vue')
const Archive = () => import(/* webpackChunkName: "admin" */ '../views/Dashboard/Project/Archive/Index.vue')


const AlternativeDashboard = () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/AlternativeDashboard.vue');
const Widgets = () => import(/* webpackChunkName: "widgets" */ '../views/Widgets.vue');

// Forms pages

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "extra" */ '../views/_ needless/_Maps/GoogleMaps.vue');
const VectorMaps = () => import(/* webpackChunkName: "extra" */ '../views/_ needless/_Maps/VectorMaps.vue');

// Pages
const User = () => import(/* webpackChunkName: "pages" */ '../views/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '../views/Pages/Pricing.vue');
const TimeLine = () => import(/* webpackChunkName: "pages" */ '../views/Pages/TimeLinePage.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '../views/Pages/Login.vue');
const ForgotPassword = () => import(/* webpackChunkName: "pages" */ '../views/Pages/ForgotPassword.vue');
const ChangePassword = () => import(/* webpackChunkName: "pages" */ '../views/Pages/ChangePassword.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '../views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '../views/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '../views/Pages/Lock.vue');
const Search = () => import('../views/Pages/Search.vue')
const NotificationRedirect = () => import('../views/Pages/NotificationRedirect.vue')


// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ '../views/_ needless/_Tables/RegularTables.vue');
const SortableTables = () => import(/* webpackChunkName: "tables" */ '../views/_ needless/_Tables/SortableTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ '../views/_ needless/_Tables/PaginatedTables.vue');

// User
const UserSettings = () => import(/* webpackChunkName: "pages" */ '../views/Pages/UserProfile.vue');

// Administration
const AdminView = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Admin.vue')
const Roles = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Roles/Roles.vue')
const Users = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Users/Users.vue')
const UserForm = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Users/Form.vue')
const Vehicles = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Vehicles/Vehicles.vue')
const VehicleForm = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Vehicles/Form.vue')
const Articles = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Articles/Articles.vue')
const ArticleForm = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Articles/Form.vue')
const Hotels = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Addresses/List.vue')
const HotelForm = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Addresses/Form.vue')
const Options = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Option/Option.vue')
const Teams = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Teams/Teams.vue')
const TeamForm = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Teams/Form.vue')

//Projects
const ProjectList = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Projects/Overview.vue')
const ProjectForm = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Projects/Form.vue')

const Chat = () => import(/* webpackChunkName: "chat" */ '../views/Dashboard/Project/Chat/Index.vue')

const loaderOptions = {
  func: [
    { call: 'fail', modifier: 'temp', argument: '#6e0000' },
    { call: 'location', modifier: 'temp', argument: 'top' },
    { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
  ]
}



// let componentsMenu = {
//   path: '/components',
//   component: DashboardLayout,
//   redirect: '/components/buttons',
//   name: 'Components',
//   children: [
//     {
//       path: 'buttons',
//       name: 'Buttons',
//       component: Buttons
//     },
//     {
//       path: 'cards',
//       name: 'Cards',
//       component: Cards
//     },
//     {
//       path: 'grid-system',
//       name: 'Grid System',
//       component: GridSystem
//     },
//     {
//       path: 'notifications',
//       name: 'Notifications',
//       component: Notifications
//     },
//     {
//       path: 'icons',
//       name: 'Icons',
//       component: Icons
//     },
//     {
//       path: 'typography',
//       name: 'Typography',
//       component: Typography
//     }
//   ]
// };
// let formsMenu = {
//   path: '/forms',
//   component: DashboardLayout,
//   redirect: '/forms/elements',
//   name: 'Forms',
//   children: [
//     {
//       path: 'elements',
//       name: 'Form elements',
//       component:  FormElements
//     },
//     {
//       path: 'components',
//       name: 'Form components',
//       component:  FormComponents
//     },
//     {
//       path: 'validation',
//       name: 'Form validation',
//       component:  FormValidation
//     }
//   ]
// };

let tablesMenu = {
  path: '/tables',
  component: DashboardLayout,
  redirect: '/table/regular',
  name: 'Tables menu',
  children: [
    {
      path: 'regular',
      name: 'Tables',
      component: RegularTables
    },
    {
      path: 'sortable',
      name: 'Sortable',
      component: SortableTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'vector',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [

    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/forgot',
      name: 'Forgot',
      component: ForgotPassword
    },
    {
      path: '/change/:token',
      name: 'Change',
      component: ChangePassword
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    },
    { path: '*', component: NotFound }
  ]
};

const adminPages = {
  path: '/',
  component: DashboardLayout,
  redirect: '/admin',
  name: 'admin',
  children: [
    {
      path: '/admin',
      name: 'uebersicht',
      component: AdminView,
      meta: {
        noBodyBackground: false,
        progress: loaderOptions
      }
    },
    {
      path: '/admin/roles',
      name: 'rollen',
      component: Roles,
      meta: {
        noBodyBackground: false,
        progress: loaderOptions
      }
    },
    {
      path: '/admin/users',
      name: 'user',
      component: Users,
      meta: {
        noBodyBackground: false,
        progress: loaderOptions
      }
    },
    {
      path: '/admin/users/:userId',
      component: UserForm,
      meta: {
        bcDynamic: true,
        bcGetter: 'user',
        bcLinkText: user => user.name,
        progress: loaderOptions
      }
    },
    {
      path: '/admin/vehicles',
      name: 'vehicles',
      component: Vehicles,
      meta: {
        progress: loaderOptions
      }
    },
    {
      path: '/admin/vehicles/form/:id',
      name: 'vehicles-details',
      component: VehicleForm,
      meta: {
        progress: loaderOptions
      }
    },
    {
      path: '/admin/articles',
      name: 'article',
      component: Articles,
      meta: {
        progress: loaderOptions
      }
    },
    {
      path: '/admin/articles/form/:id',
      name: 'article-details',
      component: ArticleForm,
      meta: {
        progress: loaderOptions
      }
    },
    {
      path: '/admin/hotels',
      name: 'hotels',
      component: Hotels,
      meta: {
        progress: loaderOptions
      }
    },
    {
      path: '/admin/hotels/form/:id',
      name: 'hotels-details',
      component: HotelForm,
      meta: {
        progress: loaderOptions
      }
    },
    {
      path: '/admin/montage',
      name: 'montage',
      component: Options,
      props: { type: 'MONTAGE', label: 'Montage' },
      meta: {
        progress: loaderOptions
      }
    },
    {
      path: '/admin/artkat',
      name: 'artkat',
      component: Options,
      props: { type: 'ARTKAT', label: 'Kategorie' },
      meta: {
        progress: loaderOptions
      }
    },
    {
      path: '/admin/teams',
      name: 'teams',
      component: Teams,
      meta: {
        progress: loaderOptions
      }
    },
    {
      path: '/admin/teams/form/:id',
      name: 'teams-details',
      component: TeamForm,
      meta: {
        progress: loaderOptions
      }
    },
  ]
}
const routes = [
  {
    path: '/',
    redirect: '/my-dashboard',
  },
  // componentsMenu,
  // formsMenu,
  tablesMenu,
  // mapsMenu,
  pagesMenu,
  adminPages,


  // {
  //   path: '/',
  //   component: DashboardLayout,
  //   redirect: '/dashboard',
  //   name: 'Allgemein',
  //   children: [
  //     {
  //       path: 'dashboard',
  //       name: 'Dashboard',
  //       component: Dashboard
  //     },
  //     {
  //       path: 'alternative',
  //       name: 'Alternative',
  //       component: AlternativeDashboard,
  //       meta: {
  //         navbarClasses: 'bg-secondary navbar-light',
  //         searchClasses: 'navbar-search-dark'
  //       }
  //     },


  //     {
  //       path: 'widgets',
  //       name: 'Widgets',
  //       component: Widgets
  //     }
  //   ]
  // },
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/my-dashboard',
    meta: {
      hideBreadcrumb: true,
    },
    children: [
      {
        path: 'my-dashboard',
        name: 'my-dashboard',
        component: MyDashboard,
        meta: {
          progress: loaderOptions
        }
        // children: [
        //   {
        //     path: 'my-dashboard',
        //     name: 'Mein Dashboard',
        //     component: MyDashboard,
        //   },

        // ]
      },
      {
        path: '/projects',
        name: 'projects',
        component: ProjectList,
        meta: {
          noBodyBackground: false,
          progress: loaderOptions
        },
      },
      {
        path: '/projects/add',
        name: 'new-project',
        component: ProjectForm,
        meta: {
          noBodyBackground: false,
          progress: loaderOptions
        }
      },
      {
        path: '/projects/:id',
        name: 'edit-project',
        component: ProjectForm,
        meta: {
          bcDynamic: true,
          bcGetter: 'project',
          bcLinkText: project => project.name,
          progress: loaderOptions
          // altName: 'sadasds'
        }
      },
      {
        path: 'settings',
        name: 'settings',
        component: UserSettings,
        meta: {
          progress: loaderOptions
        }
      },
      {
        path: 'calendar',
        name: 'calendar',
        component: Calendar,
        meta: {
          progress: loaderOptions
        }
      },
      {
        path: 'info-pool',
        name: 'info-pool',
        component: InfoPool,
        meta: {
          progress: loaderOptions
        }
      },
      {
        path: 'tasks/:taskId?',
        component: Tasks,
        name: 'tasks',
        meta: {
          name: 'tasks',
          showCardToggle:false,
          progress: loaderOptions
        }
      },
      {
        path: 'search/:searchText',
        name: 'search',
        component: Search
      },
      {
        path: 'notification/:id',
        name: 'notification',
        component: NotificationRedirect,
        meta: {
          progress: loaderOptions
        }
      },
      {
        path: 'news/:id',
        name: 'news',
        component: NewsDetailView,
        meta: {
          progress: loaderOptions
        }
      },
      {
        path: 'logistic',
        name: 'logistic',
        component: Logistic,
        meta: {
          progress: loaderOptions
        }
      },
      {
        path: '/project/:id',
        component: ProjectDashboardLayout,
        name: 'project-dashboard',
        redirect: '/project/:id',
        meta: {
          bcDynamic: true,
          bcGetter: 'project',
          bcLinkText: project => project.name,
          // altName: 'sadasds'
        },
        children: [
          {
            path: '/project/:id',
            name: 'dashboard',
            meta: {
              progress: loaderOptions
            },
            // meta: {
            //   bcDynamic: true,
            //   bcGetter: 'project',
            //   bcLinkText: project => project.name
            //   // altName: 'sadasds'
            // },
            components: {
              project: ProjectDashboard
            }
          },
          {
            path: 'data',
            name: 'dateneingabe',
            components: {
              project: DataInput
            },
            meta: {
              progress: loaderOptions
            }
          },
          {
            path: 'docs',
            name: 'dokumente',
            components: {
              project: ProjectDocs
            },
            meta: {
              progress: loaderOptions
            }
          },
          {
            path: 'offer',
            name: 'angebot',
            components: {
              project: Offer
            },
            meta: {
              progress: loaderOptions
            }
          },
          {
            path: 'assembly-planning',
            name: 'montage',
            components: {
              project: AssemblyPlanning
            },
            meta: {
              progress: loaderOptions
            }
          },
          {
            path: 'loading-list',
            name: 'ladeliste',
            components: {
              project: LoadingList
            },
            meta: {
              hideAddressSelector: true,
              progress: loaderOptions
            }
          },
          {
            path: 'purchase',
            name: 'purchase',
            components: {
              project: Purchase
            },
            meta: {
              progress: loaderOptions
            }
          },
          {
            path: 'picking-list/:positionId?',
            name: 'picking-list',
            components: {
              project: PickingList
            },
            meta: {
              hideAddressSelector: true,
              progress: loaderOptions
            }
          },
          {
            path: 're-storage-list',
            name: 'ruecklagerungsliste',
            components: {
              project: ReStorageList
            },
            meta: {
              hideAddressSelector: true,
              progress: loaderOptions
            }
          },
          {
            path: 'info-pool',
            name: 'informationspool',
            components: {
              project: InfoPool
            },
            meta: {
              progress: loaderOptions
            }
          },
          {
            path: 'tasks/:taskId?',
            name: 'tasks',
            components: {
              project: Tasks
            },
            meta: {
              name: 'tasks',
              showCardToggle:true,
              hideAddressSelector: true,
              progress: loaderOptions
            }
          },
          {
            path: 'chat',
            name: 'chat',
            components: {
              project: Chat
            },
            meta: {
              hideAddressSelector: true,
              progress: loaderOptions
            }
            // children: [
            //   {
            //     path: ':bhbItemId',
            //     name: 'Brandinghandbuch',
            //     components: {
            //       project: BHBDetailView
            //     }
            //   }
            // ]
          },
          {
            path: 'edit',
            name: 'project-facts',
            meta: {
              hideAddressSelector: true,
              progress: loaderOptions
            },
            components: {
              project: ProjectForm
            }
          },
          {
            path: 'data/:posId',
            name: 'position',
            meta: {
              hideAddressSelector: true,
              progress: loaderOptions
            },
            components: {
              project: DataInputForm
            }
          },
          {
            path: 'data/add',
            name: 'neue-Position',
            meta: {
              hideAddressSelector: true,
              progress: loaderOptions
            },
            components: {
              project: DataInputForm
            }
          },

          {
            path: 'bhb',
            name: 'brandinghandbuch',
            components: {
              project: BHB
            },
            meta: {
              progress: loaderOptions
            }
            // children: [
            //   {
            //     path: ':bhbItemId',
            //     name: 'Brandinghandbuch',
            //     components: {
            //       project: BHBDetailView
            //     }
            //   }
            // ]
          },
          {
            path: 'debriefing',
            name: 'debriefing',
            components: {
              project: Debriefing
            },
            meta: {
              progress: loaderOptions
            }
            // children: [
            //   {
            //     path: ':bhbItemId',
            //     name: 'Brandinghandbuch',
            //     components: {
            //       project: BHBDetailView
            //     }
            //   }
            // ]
          },
          {
            path: 'archive',
            name: 'archive',
            components: {
              project: Archive
            },
            meta: {
              progress: loaderOptions
            }
          },
          {
            path: 'bhb/:posId',
            meta: {
              hideAddressSelector: true,
              bcDynamic: true,
              bcGetter: 'position',
              bcLinkText: position => position.key,
              progress: loaderOptions
              // altName: 'sadasds'
            },
            components: {
              project: BHBDetailView
            }
          },
          // {
          //   path: 'alternative',
          //   name: 'Alternative',
          //   component: AlternativeDashboard,
          //   meta: {
          //     navbarClasses: 'bg-secondary navbar-light',
          //     searchClasses: 'navbar-search-dark'
          //   }
          // },
          // {
          //   path: 'calendar',
          //   name: 'Calendar',
          //   component: Calendar
          // },
          // {
          //   path: 'charts',
          //   name: 'Charts',
          //   component: Charts
          // },
          {
            path: 'widgets',
            name: 'Widgets',
            component: Widgets,
            meta: {
              progress: loaderOptions
            }
          }
        ]
      },
      // {
      //   path: 'alternative',
      //   name: 'Alternative',
      //   component: AlternativeDashboard,
      //   meta: {
      //     navbarClasses: 'bg-secondary navbar-light',
      //     searchClasses: 'navbar-search-dark'
      //   }
      // },
      // {
      //   path: 'calendar',
      //   name: 'Calendar',
      //   component: Calendar
      // },

      {
        path: 'admin',
        component: AdminView,
        meta: {
          progress: loaderOptions
        }
      }
    ]
  },
  // {
  //   path: '/',
  //   component: DashboardLayout,
  //   name: 'Mein Dashboard',
  //   children: [
  //     {
  //       path: '/project/:id',
  //       name: 'Projekt Dashboard',
  //       component: ProjectDashboard
  //     },
  //     {
  //       path: 'project/:id/data',
  //       name: 'Dateneingabe',
  //       component: DataInput
  //     },
  //     {
  //       path: 'project/:id/add-item',
  //       name: 'Neuer Eintrag',
  //       component: DataInputForm
  //     },
  //     {
  //       path: 'project/:id/bhb',
  //       name: 'Brandinghandbuch',
  //       component: BHB
  //     },

  //     // {
  //     //   path: 'alternative',
  //     //   name: 'Alternative',
  //     //   component: AlternativeDashboard,
  //     //   meta: {
  //     //     navbarClasses: 'bg-secondary navbar-light',
  //     //     searchClasses: 'navbar-search-dark'
  //     //   }
  //     // },
  //     // {
  //     //   path: 'calendar',
  //     //   name: 'Calendar',
  //     //   component: Calendar
  //     // },
  //     // {
  //     //   path: 'charts',
  //     //   name: 'Charts',
  //     //   component: Charts
  //     // },
  //     // {
  //     //   path: 'widgets',
  //     //   name: 'Widgets',
  //     //   component: Widgets
  //     // }
  //   ]
  // },
  authPages,
];

export default routes;
