import { merge } from 'lodash'
import Base from '../crud-base-module.js'
import api from '@/util/api.js'

export default merge({
  actions: {
    loadRidesForProject({ commit, dispatch }, projectId) {
      api('findRides')
        .and('project_id', projectId)
        .with('vehicle')
        .run()
        .then(items => {
          commit('rides', items)
          return items
        })
    },
    getLogisticRides({commit, dispatch}, ride_id) {
      return dispatch('findRides', {
        data: {
          'and': {
            'id': ride_id
          },
          'with': {
            'vehicle': {
              'one': 'vehicle', 
              'this': 'vehicle_id'
            },
            'project': {
              'one': 'project', 
              'this': 'project_id',
              'query': {
                'with': {
                  'pos_mat_cat': {
                    'many': 'position',
                    'that': 'project_id',
                    'query': {
                      'with': {
                        'materials': {
                          'many': 'material',
                          'that': 'position_id',
                          'query': {
                            'with': {
                              'category': {
                                'one': 'option',
                                'this': 'category_id',
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            'address': { 
              'one': 'address', 
              'this': 'address_id'
            },

          },
          'order': {
            'load': 'DESC'
          }
        }
      })
      .then(rides => {
        commit('rides', rides);
        return rides
      })
    }
  }
}, Base('ride'))
